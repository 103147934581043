import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxSliderModule } from 'ngx-slider-v2';
import {NgChartsModule} from "ng2-charts";
import {LoadingSpinnerComponent} from "./loading-spinner/loading-spinner.component";

@NgModule({
  declarations: [AppComponent, LoadingSpinnerComponent],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxSliderModule,
    //NgCircleProgressModule,
    NgCircleProgressModule.forRoot({
      showSubtitle: false,
      showInnerStroke: true,
      units: "%",
      showUnits: true,
      animationDuration: 300,
      animation: true,
      renderOnClick: false,
    }),
    NgChartsModule
  ],
  providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy}],
  exports: [
    LoadingSpinnerComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
